import { useShow } from "@pankod/refine-core";

import { Col, Row, Show, Typography } from "@pankod/refine-antd";

import './styles.css';

const { Title, Text } = Typography;


function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}



export const CalificacionesShow = () => {

    const { queryResult } = useShow();
	const { data, isLoading } = queryResult;
	const record = data?.data;


    record && record.calificaciones.sort((a, b) => a.tetramestre > b.tetramestre ? -1 : a.tetramestre < b.tetramestre ? 1 : 0);


    const tetras = [
        { 1: 'PRIMER' },
        { 2: 'SEGUNDO' },
        { 3: 'TERCER' },
        { 4: 'CUARTO' },
        { 5: 'QUINTO' },
        { 6: 'SEXTO' }
    ];


    let sumGral, promedioGral;

    if(record){

        sumGral = record.calificaciones.reduce((prev, current) => { return { calificacion: prev.calificacion + parseFloat(current.calificacion) }}, { calificacion: 0 });

        promedioGral = numberFormat(sumGral.calificacion / record.calificaciones.length, 2);

    }




    function renderTetra(tetra){

        const keys = Object.keys(tetra);

        const materias = record.calificaciones.filter(r => r.tetramestre === keys[0]);

        if(materias.length === 0){
            return null;
        }


        const sum = materias.reduce((prev, current) => { return { calificacion: prev.calificacion + parseFloat(current.calificacion) }}, { calificacion: 0 });
        const promedio = numberFormat(sum.calificacion / materias.length, 2);


        return (

            <Col key={ `tetra_${ keys[0] }` } span={ 12 } style={{ borderBottom: 'solid 1px #ccc' }}>

                <Row gutter={ [4, 8] }>

                    <Col className="centered" span={ 24 }>
                        <Title level={ 5 }>{ tetra[keys[0]] } CUATRIMESTRE</Title>
                    </Col>

                    <Col span={ 12 }>
                        <Text strong>Materia</Text>
                    </Col>

                    <Col className="centered" span={ 4 }>
                        <Text strong>Calificacion</Text>
                    </Col>
                    <Col className="centered" span={ 5 }>
                        <Text strong>Periodo</Text>
                    </Col>
                    <Col className="centered" span={ 3 }>
                        <Text strong>Faltas</Text>
                    </Col>

                </Row>


                {
                    materias.map((recMateria, materiaIndex) =>

                        <Row className={ materiaIndex % 2 && 'alt' } gutter={ [12, 0] } key={ `materia_${ recMateria.materia }` }>

                            <Col span={ 12 }>
                                <Text >{ recMateria.materia }</Text>
                            </Col>

                            <Col className="centered" span={ 4 }>
                                <Text strong>{ recMateria.calificacion }</Text>
                            </Col>
                            <Col className="centered" span={ 5 }>
                                <Text >{ recMateria.periodo.substring(0, 3).toUpperCase() }</Text>
                            </Col>
                            <Col className="centered" span={ 3 }>
                                <Text >{ recMateria.faltas }</Text>
                            </Col>

                        </Row>

                    )

                }


                <Row style={{ marginBottom: '0.7rem', marginTop: '1rem' }}>
                    <Col className="centered" span={ 24 }>
                        <Text>Promedio: </Text>
                        <Text strong> { promedio } </Text>
                    </Col>
                </Row>

            </Col>
        )


    }


    return (

        <Show isLoading={ isLoading } title={ record?.alumno.nombre } pageHeaderProps={{ subTitle: `${ record?.alumno.matricula } - Grupo ${ record?.alumno.grupo }` }} >

            <Row gutter={ [60, 48] }>

                {
                    record && tetras.map(renderTetra)
                }

            </Row>

            <Row style={{ marginBottom: '0.7rem', marginTop: '1rem' }}>

                <Col className="centered" span={ 24 }>
                    <Title level={ 5 } style={{ display: 'inline-block' }}>Promedio general: </Title>  &nbsp;  <Title level={ 4 } style={{ display: 'inline-block' }}>{ promedioGral } </Title>
                </Col>

            </Row>

        </Show>

    )

}
